import { Controller } from '@hotwired/stimulus';
import Croppie from 'croppie';
import Swal from 'sweetalert2';

export default class FaceCropperController extends Controller {
  static targets = ['container', 'loading', 'cropper', 'file', 'form', 'filename', 'image', 'submit'];

  declare readonly hasContainerTarget: boolean;
  declare readonly containerTarget: HTMLDivElement;
  declare readonly hasLoadingTarget: boolean;
  declare readonly loadingTarget: HTMLDivElement;
  declare readonly hasCropperTarget: boolean;
  declare readonly cropperTarget: HTMLDivElement;
  declare readonly hasFileTarget: boolean;
  declare readonly fileTarget: HTMLInputElement;
  declare readonly hasFormTarget: boolean;
  declare readonly formTarget: HTMLFormElement;
  declare readonly hasFilenameTarget: boolean;
  declare readonly filenameTarget: HTMLInputElement;
  declare readonly hasImageTarget: boolean;
  declare readonly imageTarget: HTMLInputElement;
  declare readonly hasSubmitTarget: boolean;
  declare readonly submitTarget: HTMLButtonElement;

  croppie?: Croppie;

  connect() {
    if (
      !this.hasLoadingTarget ||
      !this.hasCropperTarget ||
      !this.hasFileTarget ||
      !this.hasFormTarget ||
      !this.hasFilenameTarget ||
      !this.hasImageTarget ||
      !this.hasSubmitTarget
    ) {
      Swal.fire({ icon: 'error', text: 'Unable to initialize face upload.  Missing DOM elements.' });
      return;
    }

    this.croppie = new Croppie(this.cropperTarget, {
      viewport: { width: 250, height: 250 },
      boundary: { width: 300, height: 300 },
      enableExif: true,
    });
  }

  disconnect(): void {
    if (this.croppie) {
      this.croppie.destroy();
    }
  }

  readFile() {
    if (!this.fileTarget.files?.[0]) {
      Swal.fire({ icon: 'error', text: "Sorry - your browser doesn't support the FileReader API" });
      return;
    }

    this.loadingTarget.style.display = 'block';

    const file = this.fileTarget.files[0];

    const reader = new FileReader();
    reader.addEventListener('load', this.fileReaderLoaded.bind(this));
    reader.readAsDataURL(file);

    this.filenameTarget.value = file.name;
  }

  async fileReaderLoaded(event: ProgressEvent<FileReader>) {
    if (this.hasContainerTarget) this.containerTarget.style.display = 'block';
    this.cropperTarget.classList.add('ready');

    if (!event.target?.result || !this.croppie) return;
    await this.croppie.bind({
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      url: event.target.result.toString(),
    });

    this.loadingTarget.style.display = 'none';
    this.submitTarget.removeAttribute('disabled');
  }

  async upload() {
    if (!this.croppie) return;

    const image = await this.croppie.result({
      type: 'base64',
      size: 'original',
      format: 'jpeg',
      quality: 0.8,
    });

    this.imageTarget.value = image;
    this.formTarget.submit();
  }
}
